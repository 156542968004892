import "./App.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { TopBarComponent } from "./components/TopBar/TopBar";

import { theme } from "@chakra-ui/pro-theme";
import "@fontsource/inter/variable.css";

function App() {
  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.teal },
    },
    theme
  );
  return (
    <ChakraProvider theme={myTheme}>
      <TopBarComponent />
    </ChakraProvider>
  );
}

export default App;
